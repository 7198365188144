import md5 from 'blueimp-md5';

import { name, version } from '../../package.json';
import { VersionEventData } from '../components/eaw-version-details/eaw-version-details';
import { environment } from '../environments/environment';
import { ILumappsCustomizeOptions } from '../models/lumapps-customization-api/ILumappsCustomizeOptions';
import { ILumAppsInstance } from '../models/lumapps-instance';
import { LocalStorage } from '../services/local-storage-service';
import { setCookie } from '../util/functions';
import { logDebug, logError } from '../util/logger';

import { DOMAIN_REDIRECT_KEY } from './eaw-const';

const SLACK_TEAM_ID = 'T039ZEK3W';
const CACHE_DURATION_SECONDS = 3600; // 1 hour

declare const lumapps: any;
declare const angular: any;
declare const APPLICATION_NAME: string;
declare const EAWorld: any;

const EAW_VERSION_DETAILS: VersionEventData[] = [];
const urlParams = new URLSearchParams(window.location.search);

window['EAW_VERSION_DETAILS'] = EAW_VERSION_DETAILS;

document.addEventListener('eaw-version-details-loaded', (event: CustomEvent<VersionEventData>) => {
  EAW_VERSION_DETAILS.push(event.detail);
  // console.log('eaw-bootstrap.eaw-version-details-loaded', event.detail);
});

const versionDetailsEvent = new CustomEvent<VersionEventData>('eaw-version-details-loaded', {
  detail: {
    displayName: 'EA World Base',
    packageName: name,
    packageVersion: version,
  },
});

document.dispatchEvent(versionDetailsEvent);

const scriptsToLoad = [
  `${environment.cloudfrontUrl}/assets/js/eaw-lumapps${environment.minimizeCode ? '.min' : ''}.js`,
  `${environment.cloudfrontUrl}/build/eaw-web-components.esm.js`,
  `https://static.cloud.coveo.com/atomic/v2/atomic.esm.js`
];

window['EAWorld'] = {
  logMessages: [],
  verboseLogging: environment.verboseLogging
};

// Test to see if this browser support dynamic imports (ES6). If not, a polyfills probably need to be used.
const doesNotSupportDynamicImports = () => {
  try {
    Function('import("")');

    return false;
  }
  catch (e) {
    // Does not support dynamic imports
  }

  return true;
}

const loadScripts = (scripts: string[]) => {
  logDebug('eaw-bootstrap-init.loadScripts()', scripts);

  scripts.forEach(script => {
    const scriptTag = document.createElement('script');

    // If the browser doesn't support dynamic imports, load a non esm version of the script. The non esm version will likely have the polyfills needed. This is the case with stencil builds at least...
    if (script.indexOf('.esm.js') !== -1 && doesNotSupportDynamicImports()) {
      logDebug(`${script} will not be added to the page as the browser does not appear to support dynamic imports. Will attempt to load a non esm version of the script instead.`);

      script = script.replace('.esm.js', '.js');

      // To make sure the other stencil scripts load from the right place, we need to set the data-resources-url attribute on the script tag.
      scriptTag.setAttribute('data-resources-url', `${environment.cloudfrontUrl}/build/`);

      scriptTag.defer = true;
    }

    scriptTag.src = script;

    if (script.indexOf('.esm.js') !== -1) {
      scriptTag.type = 'module';
    }

    document.head.appendChild(scriptTag);

    logDebug(`${script} added to page`);
  });

  logDebug('done with scripts');
}

const saveLocalData = (key: string, value: any, expiration?: number) => {
  try {
    if (expiration) {
      LocalStorage.set(key, value, expiration);
    } else {
      LocalStorage.set(key, value);
    }
  } catch (err) {
    logError('saveLocalData: Failed to save data.', err);
  }
}

const interceptXhrRequests = () => {
  logDebug('Common.interceptXhrRequests()', undefined, 5);

  // tslint:disable-next-line: no-unbound-method
  const open = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function (method: string, url: string, async?: boolean) {
    logDebug(`Common.interceptXhrRequests.open: ${method} ${url}`);

    if (url.indexOf('/v1/email/send') !== -1) {
      url = `${environment.lumappsServiceUrl}/v1/email/send`;
    }

    return open.apply(this, [].slice.call([method, url, async]));
  };
}

// for backwards compatibility, we're prefetching all of the calls to get everything setup
const init = async () => {
  interceptXhrRequests();
  const hostname = window.location.hostname;

  if (urlParams.get('verboseLogging') === 'true') {
    EAWorld.verboseLogging = true;
  }

  logDebug('eaw-bootstrap-init.init()');

  LocalStorage.removeExpiredValues();

  switch (urlParams.get(DOMAIN_REDIRECT_KEY)) {
    case 'false':
      logDebug(`NO ${DOMAIN_REDIRECT_KEY}`);
      saveLocalData(DOMAIN_REDIRECT_KEY, false);
      break;
    case 'true':
      LocalStorage.delete(DOMAIN_REDIRECT_KEY);
  }

  const domainRedirect = LocalStorage.get(DOMAIN_REDIRECT_KEY);

  logDebug('domainRedirect', domainRedirect);

  if (domainRedirect !== false) {
    if (hostname === 'sites.lumapps.com') {
      window.location.replace(`https://eaworld.work.ea.com${window.location.pathname.substring(5)}`);
      return;
    }

    if (hostname === 'sites-test.lumapps.com') {
      window.location.replace(`https://eaintranet-integration.ea.com${window.location.pathname.substring(5)}`);
      return;
    }
  }

  window['SLACK_TEAM_ID'] = SLACK_TEAM_ID;

  // Set the available LumApps widgets
  (() => {
    'use strict';

    const runScript = ($rootScope, Settings, ConfigInstance, User, Widget) => {
      'ngInject';
      logDebug('eaw-bootstrap-init.runScript', [$rootScope, Settings, ConfigInstance, User, Widget]);

      $rootScope.$on('$locationChangeStart', (event, newUrl, oldUrl) => {
        logDebug(`$rootScope.$locationChangeStart`, [event, newUrl, oldUrl]);

        if (newUrl !== oldUrl) {
          const locationChangeStartEvent = new CustomEvent('eaw-location-change-start', {
            detail: {
              newUrl,
              oldUrl,
              maxBottomPageVisiblePercentage: window['eaGtmMaxBottomPageVisiblePercentage'] || -1
            }
          });

          document.dispatchEvent(locationChangeStartEvent);
        }
      });

      $rootScope.$on('$locationChangeSuccess', (event, newUrl, oldUrl) => {
        logDebug(`$rootScope.$locationChangeSuccess`, [event, newUrl, oldUrl]);

        if (newUrl !== oldUrl) {
          const locationChangeSuccessEvent = new CustomEvent('eaw-location-change-success', {
            detail: {
              newUrl,
              oldUrl,
              maxBottomPageVisiblePercentage: window['eaGtmMaxBottomPageVisiblePercentage'] || -1
            }
          });

          document.dispatchEvent(locationChangeSuccessEvent);
        }
      });
    }

    try {
      if (typeof angular !== 'undefined') {
        angular.module(typeof APPLICATION_NAME !== 'undefined' ? APPLICATION_NAME : 'LumSites').run(runScript);
      } else {
        const availableWidgetsInterval = setInterval(() => {
          if (typeof angular !== 'undefined') {
            clearInterval(availableWidgetsInterval);
            window['angular'].module(typeof APPLICATION_NAME !== 'undefined' ? APPLICATION_NAME : 'LumSites').run(runScript);
          }
        }, 100);
      }
    }
    catch (e) {
      // Problem with setting available widget
    }
  })();

  // TODO: replace this with lumapps customization api if they add the AUTH_TOKEN to the session or something as we requested.
  // get AUTH TOKEN
  if (typeof window['AUTH_TOKEN'] === 'undefined' || window['AUTH_TOKEN'] === '') {
    const tokenKey = 'auth-token';
    const authToken = LocalStorage.get(tokenKey) || undefined;

    if (authToken) {
      logDebug('Auth Token from cache ', authToken);
      window['AUTH_TOKEN'] = authToken;
    } else {
      logDebug('AUTH_TOKEN not defined, fetching...');

      const request = await fetch('/service/user/token', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          customerId: environment.customerId
        })
      });

      const response = await request.json();

      window['AUTH_TOKEN'] = response.token;
      saveLocalData(tokenKey, window['AUTH_TOKEN'], CACHE_DURATION_SECONDS);

      document.dispatchEvent(new Event('eaw-auth-token-set'));
    }
  }

  loadScripts(scriptsToLoad);
};

lumapps.customize((customizeOptions: ILumappsCustomizeOptions) => {
  const { instance, language, organization, user } = customizeOptions.session;
  // Build a connected user object to mimic the old CONNECTED_USER (which is an ILumAppsConnectedUser)
  const connectedUser = {
    apiProfile: {
      addresses: user.addresses,
      customSchemas: user.customSchemas,
      externalIds: user.externalIds
    },
    fullName: user.fullName,
    id: user.id,
    instancesSuperAdmin: user.instancesSuperAdmin,
    isSuperAdmin: user.isSuperAdmin,
    lang: user.lang,
    langs: user.langs,
    customer: organization.id
  };
  // Build an instance object to mimic the old INSTANCE (which is an ILumAppsInstance)
  const currentInstance: ILumAppsInstance = {
    customer: organization.id,
    defaultLang: language, // TODO: replace this with instance.defaultLang if Lumapps implements it for us in the customization api
    id: instance.id,
    langs: instance.langs,
    name: instance.name,
    parent: instance.parent || null,
    slug: instance.slug,
    title: instance.title || { en: instance.name }
  }

  logDebug('eaw-bootstrap: lumapps.customize.session', customizeOptions.session);

  window['CONNECTED_USER'] = connectedUser;
  window['LUMAPPS_USER'] = user;
  window['INSTANCE'] = currentInstance;

  if (user.isSuperAdmin) {
    document.body.classList.add('eaw-user--super-admin');
  } else {
    document.body.classList.remove('eaw-user--super-admin');
  }

  const getPreferredTheme = () => {
    const storedTheme = LocalStorage.get('eaw-theme');

    if (storedTheme) {
      return storedTheme;
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  const setTheme = theme => {
    if (theme === 'system') {
      document.documentElement.setAttribute('data-eaw-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
    } else {
      document.documentElement.setAttribute('data-eaw-theme', theme);
    }
  }

  if (environment.enableDarkMode) {
    setTheme(getPreferredTheme());
  } else {
    setTheme('light');
  }

  if (urlParams.get('eawTheme')) {
    setTheme(urlParams.get('eawTheme'));
  }

  // Hash the user employee id so we can use it in google analytics
  const employeeIdExternalId = connectedUser.apiProfile?.externalIds?.find(externalId => externalId.type === 'organization');

  if (employeeIdExternalId) {
    const employeeId = employeeIdExternalId.value;
    const hashedEmployeeId = md5(employeeId);

    setCookie('eaw-gtm-user-id', hashedEmployeeId);
  }

  init();

  let pageHref = location.href;

  customizeOptions.onNavigation(() => {
    logDebug('lumapps.customize.onNavigation');

    const event = new CustomEvent('eaw-on-navigation', {
      detail: {
        previousUrl: pageHref,
        url: location.href
      }
    });

    document.dispatchEvent(event);

    pageHref = location.href;
  });
});
