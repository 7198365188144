import Cookies from 'js-cookie';
import snarkdown from 'snarkdown';

import { ILumAppsConnectedUser } from '../models/lumapps-connected-user';
import { ILumAppsInstance } from '../models/lumapps-instance';
import { ILumAppsUserRole } from '../models/lumapps-user-role';

declare const CONNECTED_USER: ILumAppsConnectedUser;
declare const USER_ROLES: ILumAppsUserRole[];
declare const INSTANCE: ILumAppsInstance;

export const copyTextToClipboard = (text: string): Promise<boolean> => {
  return new Promise((resolve) => {
    navigator.clipboard.writeText(text).then(
      () => {
        resolve(true);
      },
      (err) => {
        console.error('copyTextToClipboard: Could not copy text: ', err);
        resolve(false);
      }
    );
  });
}

/**
 * Returns the difference between 2 dates.
 *
 * *Note: This may not be 100% accurate when there are leap years in the date range.*
 *
 * @param firstDate The first date to compare. This should be the earlier date.
 * @param secondDate The second date to compare. This should be the later date.
 * @param datePart The part of the date you want to return the difference for. Valid options (one of the following): `years` `months` `weeks` `days` `hours` `minutes` `seconds`
 * @param precise Whether or not to return a whole number or a decimal number. Default: `false`
 * @todo Evaluate if this should be modified to account for leap years
 */
export const dateDiff = (firstDate: Date, secondDate: Date, datePart: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds', precise?: boolean) => {
  const timeDiff = secondDate.getTime() - firstDate.getTime();
  const zoneDelta = (secondDate.getTimezoneOffset() - firstDate.getTimezoneOffset()) * 6e4;
  let output = timeDiff;

  // Adapted from moment.js, https://github.com/moment/moment/blob/develop/src/lib/moment/diff.js#L54-L79
  const monthDiff = (endDate: Date, startDate: Date): number => {
    if (endDate < startDate) {
      // end-of-month calculations work correct when the start month has more
      // days than the end month.
      return -monthDiff(startDate, endDate);
    }
    // difference in months
    const wholeMonthDiff = (startDate.getFullYear() - endDate.getFullYear()) * 12 + (startDate.getMonth() - endDate.getMonth());
    // b is in (anchor - 1 month, anchor + 1 month)
    const anchor = new Date(endDate);
    anchor.setMonth(endDate.getMonth() + wholeMonthDiff);
    let anchor2: Date;
    let adjust: number;

    if (startDate.getTime() - anchor.getTime() < 0) {
      anchor2 = new Date(endDate);
      anchor2.setMonth(anchor2.getMonth() + (wholeMonthDiff - 1));
      // linear across the month
      adjust = (startDate.getTime() - anchor.getTime()) / (anchor.getTime() - anchor2.getTime());
    } else {
      anchor2 = new Date(endDate);
      anchor2.setMonth(anchor2.getMonth() + (wholeMonthDiff + 1));
      // linear across the month
      adjust = (startDate.getTime() - anchor.getTime()) / (anchor2.getTime() - anchor.getTime());
    }

    // check for negative zero, return zero if negative zero
    return -(wholeMonthDiff + adjust) || 0;
  };

  switch (datePart) {
    case 'years':
      output = monthDiff(secondDate, firstDate) / 12;

      break;
    case 'months':
      output = monthDiff(secondDate, firstDate);

      break;
    case 'weeks':
      output = (timeDiff - zoneDelta) / 6048e5; // 1000 * 60 * 60 * 24 * 7, negate dst

      break;
    case 'days':
      output = (timeDiff - zoneDelta) / 864e5; // 1000 * 60 * 60 * 24, negate dst

      break;
    case 'hours':
      output = timeDiff / 36e5; // 1000 * 60 * 60

      break;
    case 'minutes':
      output = timeDiff / 6e4; // 1000 * 60

      break;
    case 'seconds':
      output = timeDiff / 1e3; // 1000

      break;
  }

  if (precise) {
    return output;
  } else {
    if (output < 0) {
      // -0 -> 0
      return Math.ceil(output) || 0;
    } else {
      return Math.floor(output);
    }
  }
};

/**
 * Converts number of days into seconds.
 *
 * @param days The number of days to convert into seconds
 */
export const daysToSeconds = (days: number): number => {
  return days * hoursToSeconds(24);
}

/**
 * Converts number of hours into seconds.
 *
 * @param hours The number of hours to convert into seconds
 */
export const hoursToSeconds = (hours: number): number => {
  return hours * minutesToSeconds(60);
}

/**
 * Converts number of minutes into milliseconds.
 *
 * @param minutes The number of minutes to convert into milliseconds
 */
export const minutesToMilliseconds = (minutes: number): number => {
  return minutes * 60000;
}

/**
 * Converts number of minutes into seconds.
 *
 * @param minutes The number of minutes to convert into seconds
 */
export const minutesToSeconds = (minutes: number): number => {
  return minutes * 60;
}

/**
 * Converts number of weeks into seconds.
 *
 * @param weeks The number of weeks to convert into seconds
 */
export const weeksToSeconds = (weeks: number): number => {
  return weeks * daysToSeconds(7);
}

/**
 * Formats a date with the specified format.
 *
 * Accepted patterns:
 *
 * | Token       | Description                          | Example |
 * |-------------|--------------------------------------|---------|
 * | S           | millisecond, no padding              | 54      |
 * | SSS         | millisecond, padded to 3             | 054     |
 * | s           | second, no padding                   | 4       |
 * | ss          | second, padded to 2 padding          | 04      |
 * | m           | minute, no padding                   | 7       |
 * | mm          | minute, padded to 2                  | 07      |
 * | h           | hour in 12-hour time, no padding     | 1       |
 * | hh          | hour in 12-hour time, padded to 2    | 01      |
 * | H           | hour in 24-hour time, no padding     | 9       |
 * | HH          | hour in 24-hour time, padded to 2    | 13      |
 * | d           | day of the month, no padding         | 6       |
 * | dd          | day of the month, padded to 2        | 06      |
 * | L (or M)    | month as an unpadded number          | 8       |
 * | LL (or MM)  | month as an padded number            | 08      |
 * | yy          | two-digit year                       | 14      |
 * | yyyy        | four-digit year                      | 2014    |
 *
 * @param input The date we want the format for.
 * @param format The format we want to use for the output.
 * @todo Add support for passing in "escaped" characters that are returned exactly as they are passed in (minus the escape character). Ex: Passing in 'yyyy' should return exactly yyyy and not try to replace with the year.
 */
export const formatDate = (input: Date, format: string): string => {
  const year = input.getFullYear();
  const month = input.getMonth() + 1;
  const date = input.getDate();
  const hours = input.getHours();
  const minutes = input.getMinutes();
  const seconds = input.getSeconds();
  const milliseconds = input.getMilliseconds();
  let hours12 = hours;

  if (hours12 > 12) {
    hours12 = hours12 - 12;
  }

  return format
    .replace(/(yyyy){1}/g, `${year}`)
    .replace(/(yy){1}/g, `${year}`.substr(2))
    .replace(/(MM|LL){1}/g, padStart(month, 2, '0'))
    .replace(/(M|L){1}/g, `${month}`)
    .replace(/(dd){1}/g, padStart(date, 2, '0'))
    .replace(/d{1}/g, `${date}`)
    .replace(/(HH){1}/g, padStart(hours, 2, '0'))
    .replace(/H{1}/g, `${hours}`)
    .replace(/(hh){1}/g, padStart(hours12, 2, '0'))
    .replace(/h{1}/g, `${hours12}`)
    .replace(/(mm){1}/g, padStart(minutes, 2, '0'))
    .replace(/m{1}/g, `${minutes}`)
    .replace(/(ss){1}/g, padStart(seconds, 2, '0'))
    .replace(/s{1}/g, `${seconds}`)
    .replace(/(SSS){1}/g, padStart(milliseconds, 3, '0'))
    .replace(/S{1}/g, `${milliseconds}`);
};

/**
 * Returns (in ISO format YYYY-MM-DD) just the date portion of the date.
 *
 * @param date The Date to return the date portion from.
 */
export const getISODate = (date: Date): string => {
  return date.toISOString().substr(0, 10);
};

/**
 * Returns (in ISO format YYYY-MM-DD) just the date portion of the date after converting to the timezone.
 *
 * Note: This is a bit hacky and probably isn't the best way to do this, but it might be better than including a full date library just to handle this type of thing.
 *
 * @param date The Date to return the date portion from.
 * @param timeZone The timezone we want to return the date in.
 */
export const getISODateForTimezone = (date: Date, timeZone: string): string => {
  const localeDateString = date.toLocaleDateString('en', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone });

  return `${localeDateString.substr(6, 4)}-${localeDateString.substr(0, 2)}-${localeDateString.substr(3, 2)}`;
};

/**
 * Returns (in ISO format HH:mm) just the time portion of the date after converting to the timezone.
 *
 * @param date The Date to return the time portion from.
 * @param timeZone The timezone we want to return the time for.
 */
export const getISOTimeForTimezone = (date: Date, timeZone: string): string => {
  const localeTimeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hourCycle: 'h23', timeZone };

  return date.toLocaleTimeString('en', localeTimeOptions);
};

/**
 * Returns the value of a query string parameter given the name.
 *
 * Uses built in {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams|URLSearchParams} for this.
 *
 * @param name The name of the parameter to get
 */
export const getUrlParameter = (name: string) => {
  const urlParams = new URLSearchParams(location.search);

  return urlParams.get(name);
};

/**
 * Returns a boolean indicating if the user has publishing permissions for the current site.
 *
 */
export const userHasLumAppsPublishingPermission = (): boolean => {
  const publisherRoles = ['contributors', 'publishers'];

  if (CONNECTED_USER.isSuperAdmin) {
    return true;
  }

  if (CONNECTED_USER.instancesSuperAdmin && CONNECTED_USER.instancesSuperAdmin.indexOf(INSTANCE.id) !== -1) {
    return true;
  }

  // TODO: I don't think USER_ROLES is ever defined anywhere, so this code will never run. If we ever get around to implementing roles properly, we might need to re-evaluate this.
  if (typeof USER_ROLES !== 'undefined' && USER_ROLES.length) {
    const foundRole = USER_ROLES.find(userRole => {
      return publisherRoles.indexOf(userRole.name.toLowerCase()) !== -1;
    });

    if (foundRole) {
      return true;
    }
  }

  return false;
};

/**
 * Generates a random base 36 string.
 *
 * This is a utility class for when you just need a random id for something like an {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/id|Element.id}.
 *
 * @returns A 10 digit randomly generated base 36 string padded with 0 to a length of 10.
 */
export const generateId = (): string => {
  return Math.random().toString(36).substr(2, 10).padEnd(10, '0');
};

/**
 * Get the position and size of an HTMLElement.
 *
 * @param element The element we want to get the position of.
 * @returns An object with the top, left, width and height of the element.
 */
export const getElementPosition = (element: HTMLElement | string) => {
  if (typeof element === 'string') {
    if (element.startsWith('#')) {
      element = document.getElementById(element.substr(1));
    } else {
      element = document.querySelector(element) as HTMLElement;
    }
  }

  const rect = element.getBoundingClientRect();
  const scrollLeft = typeof window.pageXOffset !== 'undefined' ? window.pageXOffset : document.documentElement.scrollLeft;
  const scrollTop = typeof window.pageYOffset !== 'undefined' ? window.pageYOffset : document.documentElement.scrollTop;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, width: rect.width, height: rect.height };
};

/**
 * Gets the `checked` property of an {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement|HTMLInputElement} that is a child of the `form`.
 *
 * @param form The {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement|HTMLFormElement} that is the parent of the `input` element.
 * @param name The name of the `input` element.
 * @returns The `input.checked` property or null if the `input` wasn't found in the `form`.
 */
export const getFormCheckboxChecked = (form: HTMLFormElement, name: string): boolean => {
  const formElement = form.querySelector(`input[name="${name}"]`) as HTMLInputElement;

  if (!formElement || formElement.type !== 'checkbox') {
    return null;
  }

  return formElement.checked;
};

/**
 * Gets the `value` from an {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement|HTMLInputElement} that is a child of the `form`.
 *
 * @param form The {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement|HTMLFormElement} that is the parent of the `input` element.
 * @param name The name of the `input` element.
 * @returns The `input.value` or null if the `input` wasn't found in the `form`.
 */
export const getFormInputValue = (form: HTMLFormElement, name: string) => {
  const formElement = form.querySelector(`input[name="${name}"]`) as HTMLInputElement;

  if (!formElement) {
    return null;
  }

  return formElement.value;
};

/* istanbul ignore next: We have to ignore this from the unit test coverage report because stencil doesn't currently have a mock for the select element */
/**
 * Gets the `value` from an {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement|HTMLInputElement} that is a child of the `form`.
 *
 * @param form The {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement|HTMLFormElement} that is the parent of the `input` element.
 * @param name The name of the `input` element.
 * @returns The `input.value` or null if the `input` wasn't found in the `form`.
 */
export const getFormSelectValue = (form: HTMLFormElement, name: string) => {
  const formElement = form.querySelector(`select[name="${name}"]`) as HTMLSelectElement;

  if (!formElement) {
    return null;
  }

  return formElement.value;
};

/* istanbul ignore next: Unit test can not be run because FormData isn't available as expected. Will likely need to create a mock of it to get unit testing working. */
/**
 * Gets a javascript object from the elements of a `form`;
 *
 * @param form The {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement|HTMLFormElement} to generate the object from.
 * @returns An object with keys/values based on the form data.
 */
export const getObjectFromForm = <T>(form: HTMLFormElement): T => {
  const formData = new FormData(form);
  const values = {};

  for (const [key, value] of formData.entries()) {
    if (values[key]) {
      if (!(values[key] instanceof Array)) {
        values[key] = new Array(values[key]);
      }

      values[key].push(value);
    } else {
      values[key] = value;
    }
  }

  return values as T;
};

/* istanbul ignore next: We have to ignore this from the unit test coverage report because stencil doesn't currently have a mock for the textarea element */
/**
 * Gets the `value` from an {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLTextAreaElement|HTMLTextAreaElement} that is a child of the `form`.
 *
 * @param form The {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement|HTMLFormElement} that is the parent of the `textarea` element.
 * @param name The name of the `textarea` element.
 * @returns The `textarea.value` or null if the `textarea` wasn't found in the `form`.
 */
export const getFormTextAreaValue = (form: HTMLFormElement, name: string) => {
  const formElement = form.querySelector(`textarea[name="${name}"]`) as HTMLTextAreaElement;

  if (!formElement) {
    return null;
  }

  return formElement.value;
};

/**
 * Tries to get the page slug using window.location.pathname.
 *
 * Just returns the last part of the pathname when split by the / character.
 *
 * @returns The slug if it was found or null if it couldn't be found (wasn't enough parts in the pathname).
 */
export const getPageSlug = (): string => {
  // Strip off the first slash, as we don't need it
  const paths = window.location.pathname.substr(1).split('/');

  if (paths.length >= 2) {
    const pageSlug = paths[paths.length - 1];

    return pageSlug;
  }

  return null;
}

/**
 * Wrapper for the built in {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd|padEnd} function that accepts strings, numbers and dates as the `input`. The regular `padEnd` function is a prototype of String.
 *
 * *Note, this function used to be a sort of polyFill, as `padEnd` wasn't supported in older IE. But as we don't support IE anymore, this isn't really needed for that purpose. So instead it is being used as a utility function now that works on more data types.*
 *
 * @param input The value we want to pad at the end.
 * @param targetLength The length of the resulting string once the `input` has been padded. If the value is lower than `input.length`, then `input` will be returned as-is.
 * @param padString The string to pad the `input` with. If `padString` is too long to stay within `targetLength`, it will be truncated. The default value for this parameter is " " *(U+0020 'SPACE')*.
 * @returns A {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String|String} of the specified `targetLength` with the `padString` applied at the end of the `input`.
 */
export const padEnd = (input: string | number | Date, targetLength: number, padString: string) => {
  if (input === null || input === undefined || typeof input === 'undefined') {
    input = '';
  }

  return `${input}`.padEnd(targetLength, padString);
};

/**
 * Wrapper for the built in {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart|padStart} function that accepts strings, numbers and dates as the `input`. The regular `padStart` function is a prototype of String.
 *
 * *Note, this function used to be a sort of polyFill, as `padStart` wasn't supported in older IE. But as we don't support IE anymore, this isn't really needed for that purpose. So instead it is being used as a utility function now that works on more data types.*
 *
 * @param input The value we want to pad from the start.
 * @param targetLength The length of the resulting string once the `input` has been padded. If the value is less than `input.length`, then `input` is returned as-is.
 * @param padString The string to pad the `input` with. If `padString` is too long to stay within the `targetLength`, it will be truncated from the end. The default value is " " *(U+0020 'SPACE')*.
 * @returns A {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String|String} of the specified `targetLength` with `padString` applied from the start.
 */
export const padStart = (input: string | number | Date, targetLength: number, padString: string) => {
  if (input === null || input === undefined || typeof input === 'undefined') {
    input = '';
  }

  return `${input}`.padStart(targetLength, padString);
};

/**
 * Returns an array sorted by a field.
 *
 * @param sourceArray The array to sort.
 * @param sortField The field used to sort the array.
 */
export const sortArray = <T>(sourceArray: T[], sortField: string): T[] => {
  const sortedArray = [...sourceArray];
  const getFieldValue = (item: T) => {
    return sortField.split('.').reduce((o,i)=> o[i], item);
  }

  sortedArray.sort((a, b) => {
    const fieldType = typeof getFieldValue(a);

    if (fieldType === 'string') {
      const valueA = getFieldValue(a).toUpperCase();
      const valueB = getFieldValue(b).toUpperCase();

      if (valueA < valueB) {
        return -1;
      }

      if (valueA > valueB) {
        return 1;
      }

      return 0;
    } else {
      return getFieldValue(a) - getFieldValue(b);
    }
  });

  return sortedArray;
};

/**
 * Returns a randomly sorted array.
 *
 * @param sourceArray The array to randomly sort.
 */
export const randomizeArray = <T>(sourceArray: T[]): T[] => {
  if (sourceArray && sourceArray.length > 0) {
    const sortedArray = [...sourceArray];

    for (let i = sortedArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = sortedArray[i];

      sortedArray[i] = sortedArray[j];
      sortedArray[j] = temp;
    }

    for (let index = 0; index < sortedArray.length; index++) {
      (sortedArray[index] as any).sortOrder = index;
    }

    return sortedArray;
  }

  return sourceArray;
};

/**
 * Deletes a cookie from the browsers cookies.
 *
 * @param name The name of the cookie to delete.
 */
export const deleteCookie = (name: string) => {
  Cookies.remove(name);
};

/**
 * Gets a cookie from the browsers cookies.
 *
 * @param name The name of the cookie to get.
 *
 * @returns The value of the cookie or `undefined` if the cookie isn't found.
 */
export const getCookie = (name: string): string => {
  return Cookies.get(name);
};

/**
 * Create a cookie, valid across the entire site.
 *
 * To expire a ticket in 15 minutes set the expires parameter to
 *
 * @param name The name of the cookie to set.
 * @param value The value to set on the cookie.
 * @param expires When the cookie will be removed. If the value is a Number, it will be interpreted as days from time of creation. Or can be a Date instance in order to set an exact expiration. If omitted, the cookie becomes a session cookie.
 * @example
 */
export const setCookie = (name: string, value: string, expires?: Date | number) => {
  const options: Cookies.CookieAttributes = {
    expires
  };

  Cookies.set(name, value, options);
};

export const parseMarkdown = (text: string) : string => {
  // return snarkdown(text);
  // Because Snarkdown is a very simple markdown processor, it doesn't wrap things in paragraphs, so we patch that here.
  return text
    .split(/(?:\r?\n){2,}/)
    .map((l) =>
      [" ", "\t", "#", "-", "*", ">"].some((char) => l.startsWith(char))
        ? snarkdown(l)
        : `<p>${snarkdown(l)}</p>`
    )
    .join("\n");
}

/**
 * Slugify a string, replacing non allowed characters with dashes.
 *
 * @param text The string we want to turn in to a slug.
 */
export const slugify = (text: string) => {
  return text
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};

/**
 * Title case a string.
 *
 * @param text The string we want to turn in to title case.
 */
export const titleCase = (text: string) => {
  const words = text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1));

  return words.join(' ');
};

/**
 * Wrap an HTMLElement around the content of the `parent` element.
 *
 * Also sets attributes on the `wrapper` element as well if the parameter was passed in.
 *
 * A plain vanilla javascript implementation inspired by the {@link https://api.jquery.com/wrapInner/|jQuery.wrapInner} function.
 *
 * @param parent The element we want to create the `wrapper` in.
 * @param wrapper A string or HTMLElement to wrap the inside of the `parent` with.
 * @param attributes Attributes to set on the `wrapper` element. Ex: { href: 'https://ea.com' }
 */
export const wrapInner = (parent: HTMLElement, wrapper: string | HTMLElement, attributes?: { [key: string]: string }) => {
  if (typeof wrapper === 'string') {
    wrapper = document.createElement(wrapper);
  }

  if (attributes) {
    for (const key of Object.keys(attributes)) {
      wrapper.setAttribute(key, attributes[key]);
    }
  }

  parent.appendChild(wrapper);

  while (parent.firstChild !== wrapper) {
    wrapper.appendChild(parent.firstChild);
  }
};
