import { LocalStorageCacheService } from '@eait/eex-applications-shared';

class LocalStorageService {
  delete(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  get(key: string) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    // If it doesn't start with a {, it probably isn't json so we can just return the value directly
    if (!itemStr.startsWith('{')) {
      return itemStr;
    }

    let item;

    try {
      item = JSON.parse(itemStr);
    } catch {
      item = null;
    }

    const now = new Date();

    // If the item doesn't exist or have a value key, it might be a legacy item that was placed in to storage before this service was implemented. In this case, we want to clear out the old item.
    if (!item || !item.value) {
      localStorage.removeItem(key);
      return null;
    }

    if (item.expires === true && now.getTime() > item.expiry) {
      localStorage.removeItem(key);

      return null;
    }

    return item.value;
  }

  removeExpiredValues() {
    for (let i = 0; i < localStorage.length; i++) {
      this.get(localStorage.key(i));
    }
  }

  set(key: string, value: any, expireSeconds = 3600) {
    const now = new Date();
    const expires = expireSeconds > 0;

    const item: { value: any; createdDate: string; expires: boolean; expiry?: number; expiryDate?: string } = {
      value,
      createdDate: now.toUTCString(),
      expires
    };

    if (expires) {
      const expiry = new Date(now.getTime() + expireSeconds * 1000);

      item.expiry = expiry.getTime();
      item.expiryDate = expiry.toUTCString();
    }

    localStorage.setItem(key, JSON.stringify(item));
  }
}

export const LocalStorage = new LocalStorageService();
export const DefaultLocalStorageService = new LocalStorageCacheService();
