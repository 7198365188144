import { environment } from '../environments/environment';

declare const EAWorld: any;

/**
 * Logs messages using the console object
 *
 * @param type The type of log message
 * @param message The message to log
 * @param optionalParams The extra parameters to log
 * @param maxCount The maximum amount of duplicate messages to log
 */
const _log = (type: 'debug' | 'error' | 'info' | 'log', message: any, optionalParams: any = undefined, maxCount = 0) => {
  let logMessage = message;

  if (optionalParams) {
    const paramMessages = [];

    if (optionalParams[Symbol.iterator]) {
      for (const param of optionalParams) {
        try {
          paramMessages.push(JSON.stringify(param));
        } catch (error) {
          paramMessages.push(`Error trying to stringify param: ${error}`);
        }
      }
    } else {
      try {
        paramMessages.push(JSON.stringify(optionalParams));
      } catch (error) {
        paramMessages.push(`Error trying to stringify optionalParams: ${error}`);
      }
    }

    logMessage = `${message} -> \n\n${paramMessages.join('\n\n')}`;
  }

  if (maxCount > 0) {
    const duplicates = EAWorld.logMessages.filter((m: string) => m === logMessage);

    // Only log the message if it has been logged less than maxCount
    if (duplicates.length < maxCount) {
      EAWorld.logMessages.push(logMessage);

      if (optionalParams) {
        console[type](message, optionalParams);
      } else {
        console[type](message);
      }

      // If we have reached the maxCount, we log an info message to state that further messages won't be logged again.
      if (duplicates.length + 1 === maxCount) {
        console.info(`The max duplicate count for this message has been reached, message won't be logged again`);
      }
    } else {
      return;
    }
  } else {
    EAWorld.logMessages.push(logMessage);

    if (optionalParams) {
      console[type](message, optionalParams);
    } else {
      console[type](message);
    }
  }
}

/**
 * Logs debug messages using the console object
 *
 * @param message The message to log
 * @param optionalParams The extra parameters to log
 * @param maxCount The maximum amount of duplicate messages to log
 */
export const logDebug = (message: any, optionalParams: any = undefined, maxCount = 0): void => {
  if (environment.verboseLogging || EAWorld.verboseLogging) {
    _log('debug', message, optionalParams, maxCount);
  }
}

/**
 * Logs error messages using the console object
 *
 * @param message The message to log
 * @param optionalParams The extra parameters to log
 * @param maxCount The maximum amount of duplicate messages to log
 */
export const logError = (message: any, optionalParams: any = undefined, maxCount = 0): void => {
  _log('error', message, optionalParams, maxCount);
}

/**
 * Logs info messages using the console object
 *
 * @param message The message to log
 * @param optionalParams The extra parameters to log
 * @param maxCount The maximum amount of duplicate messages to log
 */
export const logInfo = (message: any, optionalParams: any = undefined, maxCount = 0): void => {
  if (environment.verboseLogging || EAWorld.verboseLogging) {
    _log('info', message, optionalParams, maxCount);
  }
}

/**
 * Logs messages using the console object
 *
 * @param message The message to log
 * @param optionalParams The extra parameters to log
 * @param maxCount The maximum amount of duplicate messages to log
 */
export const log = (message: any, optionalParams: any = undefined, maxCount = 0): void => {
  _log('log', message, optionalParams, maxCount);
}
