// The file contents for the current environment will overwrite these during build.
// Do not manually change this file. Your changes will be overwritten on build.
// This file was copied from: src/environments/environment.dev.ts

export const environment = {
  businessAppsDirectoryId: '4900498517786624',
  caseManagementApiUrl: 'https://casemanagement-service-dev.eex.ea.com',
  caseManagementApiTokenAuth: 'bd015b03a419a62e9fd4260fac8b843e',
  cloudfrontUrl: 'https://assets-eaworld-dev.work.ea.com',
  coveo: {
    tokenExchangeEndpoint: 'https://ea-search-service-develop.eex.ea.com/api/v1/token?source=eaworld',
  },
  customerId: '4877796679614464',
  defaultDirectoryMaxResults: 250,
  eaPagesContentTypeId: '4895130764967936',
  enableDarkMode: true,
  enterpriseSearchNotificationDirectoryId: '3694733377650803',
  feedAllId: '5671853790068736',
  headerId: '5955075878944768',
  lumappsApiV2Host: 'https://go-cell-003.api.lumapps.com',
  lumappsServiceUrl: 'https://lumapps-service-dev.atlas-services.ea.com',
  minimizeCode: false,
  name: 'dev',
  primaryInstanceId: '4510818030845952',
  retinaPortalUrl: 'https://login-staging.work.ea.com',
  rootEnvironmentPath: '',
  rootSitePath: '/home',
  searchCloudfrontUrl: 'https://search-dev.work.ea.com',
  showDebugInfo: true,
  showVersionInfo: true,
  siteAssetsUrl: 'https://eaworld-site-assets-develop.eex.ea.com',
  siteFeedbackEmail: 'EAWorld_Feedback_DL_TEST_Stage_ENV@ea.com',
  userDirectoryContentId: '6441084546711552',
  verboseLogging: true,
  workplaceLocationsDirectoryId: '6182633819247465'
};
